<div class="clr-row">
  <div class="type-ahead-container clr-col-4">
    @if (!readonly) {
      <dpa-type-ahead
        [selectedItem]="selectedSamplingFrequency"
        [customFormatter]="typeAheadFormatter"
        [labelFormatter]="typeAheadFormatter"
        [searchableItems]="samplingFrequencyOptions"
        (selectedItemChange)="onSamplingFrequencyOptionChange($event)"
      ></dpa-type-ahead>
    } @else {
      <div>{{ selectedSamplingFrequency?.label }}</div>
    }
  </div>
</div>
