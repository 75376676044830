<div class="refresh-container">
  @if (lastUpdated) {
    <span class="mr-x2">
      {{ 'COMMON_MESSAGES.LAST_UPDATED' | translate: { time: lastUpdated | formattedDate: DateTimeFormat.MOMENT_TIME_FORMAT } }}
    </span>
  }
  <dpa-tooltip
    [tooltipText]="'COMMON_ACTIONS.REFRESH_DATA' | translate"
    [attr.aria-label]="'COMMON_ACTIONS.REFRESH_DATA' | translate"
    [focusEnabled]="true"
    (click)="refresh()"
    (keyup.enter)="refresh()"
    role="tooltip"
    class="mr-x2"
  >
    <cds-icon
      shape="refresh"
      size="18"
    ></cds-icon>
  </dpa-tooltip>
  @if (showDivider) {
    <span class="vertical-divider mr-x2"></span>
  }
</div>
