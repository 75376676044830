<div class="metric-percent-container">
  @for (metricTab of metricTabs; track metricTab.value) {
    <div class="metric-percent">
      <dpa-metric-card
        [label]="metricTab.labelKey | translate"
        [currentValue]="metricTab.value"
        [info]="metricTab.tooltipText | translate"
        [formatType]="metricTab.formatType"
        [dateTimeFormat]="metricTab.dateTimeFormat"
        [orientation]="metricTab.orientation"
        [legendStyle]="metricTab.legendStyle"
        [sparkLineTemplate]="metricTab.sparkLineTemplate"
        [iconTemplate]="legendIconTemplate"
      ></dpa-metric-card>
      <ng-template #legendIconTemplate>
        <div
          [dpaCssVars]="{
            iconColor: metricTab.iconColor
          }"
          class="legend-icon"
        ></div>
      </ng-template>
    </div>
  }
</div>
