<div
  (resized)="onResize()"
  class="chart-container"
>
  <ngx-charts-area-chart-stacked
    #chart
    [curve]="chartCurveType"
    [scheme]="ngxChart.defaultColorScheme"
    [results]="ngxChart.chartData"
    [legend]="false"
    [xAxisLabel]="ngxChart.labels.secondGroupBy"
    [yAxisLabel]="yAxisLabel"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showXAxisLabel]="true"
    [showYAxisLabel]="!!yAxisLabel"
    [animations]="true"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
    [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
    [customColors]="ngxChart.customColors"
    [timeline]="showTimeline"
    [dpaChartMarkers]="markers"
    [dpaChartMarkerTooltipTemplate]="markerTooltipTemplate"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      @if (model) {
        <ng-container
          [ngTemplateOutlet]="stackedTooltip"
          [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
        ></ng-container>
      }
    </ng-template>
    <ng-template
      #seriesTooltipTemplate
      let-model="model"
    >
      @if (model) {
        <ng-container
          [ngTemplateOutlet]="seriesTooltip"
          [ngTemplateOutletContext]="{ usingFakeGroupBy: ngxChart.usingFakeGroupBy, tooltipItems: model }"
        ></ng-container>
      }
    </ng-template>
  </ngx-charts-area-chart-stacked>
</div>
